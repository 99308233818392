<template>
	<div class="recipeIcons">
		<div :class="'icon ' + name"></div>
		<div
			class="content-icon color-aili-pink
		"
		>
			<div v-if="name == 'duration'" class="label">Tempo</div>
			<div v-if="name == 'difficulty'" class="label">Difficoltà</div>
			<div v-if="name == 'people'" class="label">Dosi</div>

			<div v-if="name == 'duration'" class="value">
				{{ value }} minuti
			</div>
			<div v-if="name == 'difficulty'" class="value">
				{{ value == "easy" ? "Facile" : "" }}
				{{ value == "medium" ? "Medio" : "" }}
				{{ value == "hard" ? "Difficile" : "" }}
			</div>
			<div v-if="name == 'people'" class="value">
				{{ value }} {{ value > 1 ? "persone" : "persona" }}
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.recipeIcons {
	display: inline-block;
	width: 100%;
	margin-bottom: 1rem;
	font-size: $font-size-14;
}

.icon {
	border-radius: 90px;
	background-color: $aili-white;
	display: inline-block;
	width: 40px;
	height: 40px;
	background-position: center center;
	background-size: 30px;
	background-repeat: no-repeat;
}

.icon.duration {
	background-image: url(../../img/duration.svg);
}
.icon.people {
	background-image: url(../../img/people.svg);
}
.icon.difficulty {
	background-image: url(../../img/difficulty.svg);
}

.content-icon {
	width: calc(100% - 60px);
	float: right;
}
</style>

<script>
export default {
	name: "RecipeIcon",
	props: ["value", "name"]
};
</script>
