<template>
	<article
		class="link content-shadow background-aili-white"
		@click="(e) => goTo('Article', { slug: slug }, e)"
		:title="title"
	>
		<div class="image-card" v-if="image" :style="'background-image:url(' + image.large + ')'"></div>
		<div class="text">
			<div class="categories tiny">
				<div class="post-type">Articolo</div>

				<div class="separator" v-if="categories"></div>
				<div class="category" v-for="(category, j) in categories" :key="j">
					<span class="category-name">{{ category.name }}</span>
					<span class="category-comma" v-if="j < categories.length - 1">,</span>
				</div>
			</div>
			<h3 :class="'font-size-25 color-aili-purple'" v-html="title" />
			<div class="author-wrap">
				<div
					class="author-image-single"
					:style="'background-image: url(' + author.images.thumbnail + ')'"
				></div>
				<h5 class="author-name color-aili-black font-size-15">
					{{ author.first_name + ' ' + author.last_name }}
				</h5>
			</div>
			<div v-if="content" v-html="content" />
		</div>
	</article>
</template>
<script>
export default {
	props: ['slug', 'author', 'preview_data', 'image', 'title', 'content', 'categories'],
};
</script>

<style lang="scss" scoped>
article {
	display: contents;
}
.categories {
	margin-bottom: 6px;
	font-size: 12px;
	text-transform: uppercase;
}
h3 {
	margin-bottom: 0;
}
.author-name {
	line-height: 40px;
	margin-bottom: 25px;
	margin-left: 50px;
	line-height: 1.4;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
.author-wrap {
	position: relative;
}
.author-image-single {
	margin-bottom: 15px;
	height: 40px;
	width: 40px;
	background-size: contain;
	border-radius: 100%;
	margin-right: 14px;
}
.link {
	position: relative;
	min-height: 300px;
	width: 100%;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	cursor: pointer;
	text-decoration: none;
	display: inline-flex;
	align-items: stretch;
	margin-bottom: $spacing-1;

	@media (max-width: $tablet-l) {
		display: inline-block;
	}

	.image-card {
		width: 40%;
		background-size: cover;
		background-position: center;
		min-width: 350px;
		@media (max-width: $tablet-l) {
			min-width: auto;
			width: 100%;
			padding-top: 50%;
		}
	}
	.text {
		padding: $spacing-1;
		width: 100%;
		float: right;
		color: #000;
		width: 60%;
		
		@media (max-width: $tablet-l) {
			width: 100%;
			padding: $spacing-0;
		}

		@media (max-width: $mobile-m) {
			padding: $spacing-0/3*2;
		}
	}
	.content-card {
		padding: $spacing-0;
		position: relative;
		min-height: 150px;
	}
	h3 {
		margin: 0;
		margin-bottom: 12px;
	}
}
</style>
