<template>
	<div class="NoResultFound">
		<h2 class="color-aili-white chantal">
			Sembra che la tua ricerca non abbia dato risultati, tranne il
			fantasma formaggino.
		</h2>
		<img class="ghost" :src="require('../../assets/ghost.png')" />
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#app {
	h1,
	h2 {
		padding: 0;
	}

	.NoResultFound {
		h2 {
			text-align: left;
			max-width: 600px;
			@media (max-width: $tablet-m) {
				font-size: 30px;
			}
		}
		text-align: center;
		margin-top: $spacing-0;
		margin-bottom: $spacing-0;

		.ghost {
			margin-top: $spacing-1;
			max-width: 525px;
			@media (max-width: $tablet-l) {
				width: 80%;
			}
		}
	}
}
</style>
