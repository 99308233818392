<template>
	<div class="recipeIcons">
		<div :class="'icon ' + name"></div>
		<div
			class="content-icon
		"
		>
			<div v-if="name == 'duration'" class="value">{{ value }} "</div>

			<div v-if="name == 'difficulty'" class="value">
				{{ value == "easy" ? "Facile" : "" }}
				{{ value == "medium" ? "Medio" : "" }}
				{{ value == "hard" ? "Difficile" : "" }}
			</div>
			<div v-if="name == 'people'" class="value">
				{{ value }}
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.recipeIcons {
	display: inline-block;
	width: 100%;
	font-size: $font-size-14;
	text-align: center;
}

.content-icon {
	color: #f62584;
}

.icon {
	border-radius: 90px;
	background-color: $aili-white;
	display: inline-block;
	width: 25px;
	height: 25px;
	background-position: center center;
	background-size: 20px;
	background-repeat: no-repeat;
}

.icon.duration {
	background-image: url(../../img/duration_light.svg);
}
.icon.people {
	background-image: url(../../img/people_light.svg);
}
.icon.difficulty {
	background-image: url(../../img/difficulty_light.svg);
}

.value {
	display: inline-block;
	text-align: center;
	width: 100%;
	top: -5px;
	position: relative;
}
</style>

<script>
export default {
	name: "RecipeIconTiny",
	props: ["value", "name"]
};
</script>
