<template>
	<section class="link background-aili-white content-shadow" @click="goTo('Recipe', { slug: slug })">
		<div class="image-card" v-if="image" :style="'background-image:url(' + image.large + ')'"></div>
		<div class="text">
			<div class="categories tiny">
				<div class="post-type">Ricetta</div>

				<div class="separator" v-if="categories"></div>
				<div class="category" v-for="(category, j) in categories" :key="j">
					<span class="category-name">{{ category.name }}</span>
					<span class="category-comma" v-if="j < categories.length - 1">,</span>
				</div>
			</div>
			<h3 :class="'font-size-25 color-aili-pink'" v-html="title">
				
			</h3>
			<div class="author-wrap">
				<div
					class="author-image-single"
					:style="'background-image: url(' + author.images.thumbnail + ')'"
				></div>
				<h5 class="author-name color-aili-black font-size-15">
					{{ author.first_name + ' ' + author.last_name }}
				</h5>
			</div>
			<div class="description-wrap">
				<p class="content-text" v-if="content" v-html="content" />
			</div>
			<div class="preview-data-big" v-if="preview_data.difficulty || preview_data.people || preview_data.duration">
				<div class="preview-data" v-if="preview_data.difficulty">
					<RecipeIcon name="difficulty" :value="preview_data.difficulty" />
				</div>
				<div class="preview-data" v-if="preview_data.people">
					<RecipeIcon name="people" :value="preview_data.people" />
				</div>
				<div class="preview-data" v-if="preview_data.duration">
					<RecipeIcon name="duration" :value="preview_data.duration" />
				</div>
			</div>

			<div class="preview-data-small" v-if="preview_data.difficulty || preview_data.people || preview_data.duration">
				<div class="columns-4" v-if="preview_data.difficulty">
					<RecipeIconTiny :value="preview_data.difficulty" name="difficulty" />
				</div>
				<div class="columns-4" v-if="preview_data.people">
					<RecipeIconTiny :value="preview_data.people" name="people" />
				</div>
				<div class="columns-4" v-if="preview_data.duration">
					<RecipeIconTiny :value="preview_data.duration" name="duration" />
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import RecipeIcon from '@/components/ui/RecipeIcon.vue';
import RecipeIconTiny from '@/components/ui/RecipeIconTiny.vue';

export default {
	components: {
		RecipeIcon,
		RecipeIconTiny,
	},
	props: ['author', 'preview_data', 'image', 'title', 'content', 'slug', 'categories'],
};
</script>

<style lang="scss" scoped>
.categories {
	margin-bottom: 6px;
	font-size: 12px;
	text-transform: uppercase;
}
h2,
h3 {
	margin-bottom: $spacing-1;
}

.author-name {
	line-height: 40px;
	margin-bottom: 25px;
	margin-left: 50px;
	line-height: 1.4;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
.author-wrap {
	position: relative;
}

.description-wrap {
	word-wrap: break-word;
	
	@media (max-width: $tablet-l) {
		padding-bottom: $spacing-1/2*3;
	}
	
	.content-text {
		@media (max-width: $tablet-l) {
			margin-bottom: $spacing-0;
		}
	}
}

.preview-data-big {
	width: 100%;
	position: absolute;
	bottom: 0;
	margin-top: $spacing-1;
	display: inline-block;
	justify-content: space-between;

	@media (max-width: $tablet-l) {
		position: relative;
		display: none;
	}

	.preview-data {
		/* @media (max-width: $tablet-l) {
			float: none;
			clear: both;
			width: 100%;
		} */
		width: 33%;
		float: left;
	}
}
.preview-data-small {
	display: none;
	border-top: 1px solid #fed3e7;
	padding-top: 10px;
	bottom: 10px;
	width: 100%;
	position: absolute;
	@media (max-width: $tablet-l) {
		right: 0;
		display: block;
		float: none;
		clear: both;
		width: 100%;
	}
}
.columns-4 {
	float: left;
	width: 33%;
}
.link {
	position: relative;
	min-height: 300px;
	width: 100%;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	cursor: pointer;
	text-decoration: none;
	display: inline-flex;
	align-items: stretch;
	margin-bottom: $spacing-1;

	@media (max-width: $tablet-l) {
		display: inline-block;
	}

	.image-card {
		width: 40%;
		background-size: cover;
		background-position: center;
		min-width: 350px;
		@media (max-width: $tablet-l) {
			min-width: auto;
			width: 100%;
			padding-top: 50%;
		}
	}
	.text {
		position: relative;
		padding: $spacing-1;
		padding-bottom: 80px;
		width: 100%;
		float: right;
		color: #000;
		width: 60%;

		@media (max-width: $tablet-l) {
			width: 100%;
			padding: $spacing-0;
		}

		@media (max-width: $mobile-m) {
			padding: $spacing-0/3*2;
		}
	}
	.content-card {
		padding: $spacing-0;
		position: relative;
		min-height: 150px;
	}
	h3,
	h4 {
		margin: 0;
		margin-bottom: 12px;
	}

	.author-image-single {
		margin-bottom: 15px;
		height: 40px;
		width: 40px;
		background-size: contain;
		border-radius: 100%;
		margin-right: 14px;
	}
}
</style>
