<template>
	<div class="background-aili-blue">
		<Header />
		<div class="content" v-if="!loadingData">
			<div class="background-aili-blue">
				<MainColumn>
					<h1 class="color-aili-white font-size-50">Ricerca per: {{ globalSearchTerm }}</h1>
					<NoResultFound v-if="results.length == 0" />

					<div class="background-aili-blue panel">
						<div v-for="(element, i) in results" :key="i">
							<div v-if="element.subtype == 'recipe'">
								<CardResultRecipe
									:slug="element.slug"
									:preview_data="element.preview_data"
									:author="element.author_data"
									:url="element.relative_url"
									:image="element.featured_media_data"
									:title="element.title"
									:content="element.post_excerpt"
									:categories="element.category_data"
								/>
							</div>
							<div v-if="element.subtype == 'page'">
								<!-- 	page -->
							</div>
							<div v-if="element.subtype == 'post'">
								<CardResultArticle
									:slug="element.slug"
									:preview_data="element.preview_data"
									:author="element.author_data"
									:url="element.relative_url"
									:image="element.featured_media_data"
									:title="element.title"
									:content="element.post_excerpt"
									:categories="element.category_data"
								/>
							</div>
						</div>
					</div>
				</MainColumn>
			</div>
		</div>
		<Loading v-else />
	</div>
</template>

<script>
import NoResultFound from '@/components/ui/NoResultFound.vue';
import Header from '@/components/ui/Header.vue';
import CardResultRecipe from '@/components/ui/CardResultRecipe.vue';
import CardResultArticle from '@/components/ui/CardResultArticle.vue';
import Loading from '@/components/ui/Loading.vue';

export default {
	name: 'Search',
	components: {
		CardResultRecipe,
		CardResultArticle,
		Header,
		Loading,
		NoResultFound,
	},
	data() {
		return {
			results: [],
		};
	},
	watch: {
		async globalSearchTerm(newValue) {
			await this.searchTermFunction(newValue);
		},
	},
	async mounted() {
		this.updateProperty({
			property: 'pageColor',
			value: 'aili-blue',
		});
		if (!this.globalSearchTerm) {
			this.updateProperty({
				property: 'globalSearchTerm',
				value: this.$route.params.term,
			});
		} else {
			await this.searchTermFunction(this.globalSearchTerm);
		}
	},
	methods: {
		async searchTermFunction(value) {
			let results = [];
			this.updateProperty({
				property: 'loadingData',
				value: true,
			});
			if (value.length > 0) {
				let response_search = await this.$api.get('wp/v2/search', {
					params: {
						search: value,
						per_page: 50,
					},
				});
				results = response_search.data;
			}
			results = results.sort((a, b) => {
				let order = ['post', 'recipe', 'others'];
				let a_subtype_order = order.findIndex((element) => element === 'others');
				let b_subtype_order = order.findIndex((element) => element === 'others');

				if (order.includes(a.subtype)) {
					a_subtype_order = order.findIndex((element) => element === a.subtype);
				}

				if (order.includes(b.subtype)) {
					b_subtype_order = order.findIndex((element) => element === b.subtype);
				}

				return a_subtype_order - b_subtype_order;
			});
			this.results = results;
			this.updateProperty({
				property: 'loadingData',
				value: false,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
h1 {
  padding: 0;
}
h2,
h3 {
  margin-bottom: $spacing-1;
}

.panel {
  position: relative;
  padding: $spacing-1;
}
.recipes {
  min-height: 100vh;
  padding-bottom: $footer-distance;
}
</style>
