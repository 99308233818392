<template>
	<v-fade-transition>
		<div class="loading" :style="{ position: position }">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				width="200px"
				height="200px"
				viewBox="0 0 100 100"
				preserveAspectRatio="xMidYMid"
			>
				<circle cx="50" cy="50" r="0" fill="none" stroke="#f72585" stroke-width="4">
					<animate
						attributeName="r"
						repeatCount="indefinite"
						dur="1s"
						values="0;40"
						keyTimes="0;1"
						keySplines="0 0.2 0.8 1"
						calcMode="spline"
						begin="-0.5s"
					></animate>
					<animate
						attributeName="opacity"
						repeatCount="indefinite"
						dur="1s"
						values="1;0"
						keyTimes="0;1"
						keySplines="0.2 0 0.8 1"
						calcMode="spline"
						begin="-0.5s"
					></animate>
				</circle>
				<circle cx="50" cy="50" r="0" fill="none" stroke="#00aeef" stroke-width="4">
					<animate
						attributeName="r"
						repeatCount="indefinite"
						dur="1s"
						values="0;40"
						keyTimes="0;1"
						keySplines="0 0.2 0.8 1"
						calcMode="spline"
					></animate>
					<animate
						attributeName="opacity"
						repeatCount="indefinite"
						dur="1s"
						values="1;0"
						keyTimes="0;1"
						keySplines="0.2 0 0.8 1"
						calcMode="spline"
					></animate>
				</circle>
			</svg>
		</div>
	</v-fade-transition>
</template>

<script>
export default {
	props: {
		position: {
			type: String,
			default: 'fixed',
		},
	},
};
</script>

<style lang="scss" scoped>
.loading {
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.6);

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
</style>
